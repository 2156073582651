<template>
  <div style="padding-top: 10px">
    <el-form label-width="100px">
      <el-row>
        <el-col :span="5">
          <el-form-item label="调货目的">
            <el-select v-model="purpose" placeholder="请选择调货目的" style="width: 100%" @change="purposeChange" clearable>
              <el-option
                  v-for="item in purposeArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
<!--        <el-col :span="5">-->
<!--          <el-form-item label="婚纱编号">-->
<!--            <el-input v-model="clothesNo" placeholder="型号编号 如: 0001 0002"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :span="5" style="margin-left: 30px">-->
<!--          <el-button type="primary" @click="search" size="medium">搜索</el-button>-->
<!--          <el-button type="warning" @click="reset" size="medium">重置</el-button>-->
<!--        </el-col>-->
      </el-row>
    </el-form>
    <el-row v-if="clothesList.length != 0">
      <el-col :offset="2" :span="20" style="padding-right: 4%">
        <span class="title">调货婚纱（全部{{clothesList.length}}）</span>
        <el-button type="success" @click="batchSetupOpen">批量设置</el-button>
        <el-button type="danger" @click="batchDelete">删除</el-button>
        <el-button type="primary" style="float: right" @click="commit">提交申请</el-button>
      </el-col>
    </el-row>
    <el-divider v-if="clothesList.length != 0"></el-divider>
    <el-row v-if="clothesList.length !== 0" class="fill_table">
        <el-table
            ref="clothesTable"
            :data="clothesList"
            @selection-change="handleSelectionChange"
        >
          <el-table-column
              width="50px"
              type="selection"
              align="center">
          </el-table-column>
          <el-table-column prop="orderNo" label="婚纱名" align="center">
            <template slot-scope="scope">
              {{scope.row.styleType}}-{{scope.row.styleName}}-{{scope.row.clothesSize}}-{{scope.row.clothesNo}}
            </template>
          </el-table-column>
<!--          <el-table-column label="是否可用" align="center">-->
<!--&lt;!&ndash;            <template slot-scope="scope">&ndash;&gt;-->
<!--&lt;!&ndash;            <span :style="{color: scope.row.scheduleState === '撞挡' ? '#f56c6c' : '#409eff'}">&ndash;&gt;-->
<!--&lt;!&ndash;              {{scope.row.scheduleState}}&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--&lt;!&ndash;            </template>&ndash;&gt;-->
<!--          </el-table-column>-->
          <el-table-column label="所在店铺" align="center" prop="shopName"></el-table-column>
          <el-table-column label="目标店铺" align="center" prop="targetShopName"></el-table-column>
          <el-table-column prop="purpose" label="调货目的" align="center"></el-table-column>
          <el-table-column label="调货周期" align="center" #default="scope" show-overflow-tooltip>
            {{scope.row.dateStart + " - " + scope.row.dateEnd}}
          </el-table-column>
          <el-table-column label="调货描述" prop="remark" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="center" v-slot="scope">
            <el-button type="text" size="medium" @click="singleSetupOpen(scope.row)">编辑</el-button>
            <el-button type="text" size="medium" style="color: red;" @click="remove(scope.row)">移除</el-button>
          </el-table-column>
        </el-table>
    </el-row>
    <order-list v-if="orderListState" :state="orderListState" @close="orderListClose" @selectClothes="selectOutGoingClothes"></order-list>
    <appoint-list v-if="appointListState" :state="appointListState" @close="appointListClose" @selectClothes="selectAppointClothes"></appoint-list>
    <batch-setup v-if="batchSetupState" :state="batchSetupState" @close="batchSetupClose" @batchSetup="batchSetup"></batch-setup>
    <single-setup v-if="singleSetupState" :state="singleSetupState" :clothes="singleSetupClothes" @close="singleSetupClose" @singleSetup="singleSetup"></single-setup>
  </div>
</template>

<script>
import orderList from "@/pages/clothesOperation/fill-operation/order-list"
import appointList from "@/pages/clothesOperation/fill-operation/appoint-list"
import batchSetup from "@/pages/clothesOperation/fill-operation/batch-setup"
import singleSetup from "@/pages/clothesOperation/fill-operation/single-setup"

export default {
  name: "fillOperation",
  components: {
    orderList,
    appointList,
    batchSetup,
    singleSetup
  },
  data() {
    return {
      purpose: "",
      purposeArray: [
        {name: "出件", value: "出件"},
        {name: "预约锁定款式", value: "预约锁定款式"},
        {name: "归还", value: "归还"},
        // {name: "分店清货", value: "分店清货"},
        // {name: "其他", value: "其他"}
      ],
      clothesNo: "",
      schedule: "",
      orderListState: false,
      clothesList: [],
      outGoingClothesChecked: false,
      header: [],
      allClothesChecked: false,
      selectedClothes: [],
      appointListState: false,
      batchSetupState: false,
      applicationClothesList: [],
      currentRowIndex: [],
      singleSetupState: false,
      singleSetupClothes: {},
      empId: JSON.parse(localStorage.getItem("emp")).id
    }
  },
  methods: {
    reset() {

    },
    search() {

    },
    purposeChange(val) {
      switch (val) {
        case "出件":
          this.orderListOpen()
          break
        case "预约锁定款式":
          this.appointListOpen()
          break
      }
    },
    orderListOpen() {
      this.orderListState = true
    },
    orderListClose() {
      this.orderListState = false
    },
    selectOutGoingClothes(val) {
      val.forEach(s => {
        s.purpose = "出件"
      })
      this.clothesList.push(...val)
    },
    handleSelectionChange(val) {
      this.applicationClothesList = val
    },
    setPurposeShop() {
      if (this.selectedClothes.length == 0) {
        this.$message.error("请选择婚纱")
        return
      }
    },
    appointListClose() {
      this.appointListState = false
    },
    appointListOpen() {
      this.appointListState =true
    },
    selectAppointClothes(val) {
      val.forEach(s => {
        s.purpose = "预约锁定款式"
      })
      this.clothesList.push(...val)
    },
    batchSetupOpen() {
      if (this.applicationClothesList.length == 0) {
        this.$message.error("请选择婚纱")
        return
      }
      this.batchSetupState = true
    },
    batchSetupClose() {
      this.batchSetupState = false
    },
    batchDelete() {
      if (this.applicationClothesList.length == 0) {
        this.$message.error("请选择婚纱")
        return
      }
      this.applicationClothesList.forEach(s => {
        this.remove(s)
      })
    },
    batchSetup(val) {
      for (let i = 0; i < this.clothesList.length; i++) {
        this.applicationClothesList.forEach(m => {
          if (this.clothesList[i] == m) {
            for (const key in val) {
              if (val[key] != null) {
                this.clothesList[i][key] = val[key]
              }
            }
            this.$set(this.clothesList, i, this.clothesList[i])
            return
          }
        })
      }
      this.batchSetupClose()
    },
    remove(val) {
      for (let i = 0; i < this.clothesList.length; i++) {
        if (val == this.clothesList[i]) {
          this.clothesList.splice(i, 1)
          break
        }
      }
    },
    singleSetupOpen(val) {
      this.singleSetupState = true
      this.singleSetupClothes = val
      this.singleSetupClothes.applicationShopId=0
    },
    singleSetupClose() {
      this.singleSetupState = false
    },
    singleSetup(val) {
      for (let i = 0; i < this.clothesList.length; i++) {
        if (this.clothesList[i] == this.singleSetupClothes) {
          this.$set(this.clothesList, i, val)
          break
        }
      }
      this.singleSetupClose()
    },
    commit() {
      for (let i = 0; i < this.clothesList.length; i++) {
        console.log(this.clothesList[i].dateRange)
        if (this.clothesList[i].targetShopId == "") {
          this.$message.error("目标店铺不能为空")
          return
        }
        if (this.clothesList[i].purpose == "") {
          this.$message.error("调货目的不能为空")
          return
        }
        if (this.clothesList[i].dateRange.length == 0) {
          this.$message.error("调货周期不能为空")
          return
        }
        if (this.clothesList[i].remark == undefined) {
          this.$message.error("调货描述不能为空")
          return
        }
      }
      this.clothesList.forEach(s => {
        s.applicationEmpId = this.empId
        s.applicationDate = new Date()
      })
      this.$axios({
        method: 'post',
        url: "/clothesOperationApplication/batchAddClothesOperationApplication",
        data: this.clothesList
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success("提交申请成功！")
          this.clothesList = []
        } else {
          this.$message.error(response.data.msg)
        }
      })
    }
  }
}
</script>

<style scoped>

.title {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
}
.purpose_title{
  font-size: 13px;
  margin-left: 1%;
}

</style>